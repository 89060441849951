/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Victor Manuel Calle Londoño                                ║
║ victor.calle@est.iudigital.edu.co                          ║
╚════════════════════════════════════════════════════════════╝
*/
import { Pagination, Stack } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import Checkbox from "@mui/material/Checkbox";
import React, { useEffect, useState } from "react";
import verDetallesImg from "assets/images/ver_detalles.svg";
import verTablaImg from "assets/images/ver tabla.svg";
import PropTypes from "prop-types";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FindInPageOutlinedIcon from "@mui/icons-material/FindInPageOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import MDButton from "components/MDButton";
import usePermission from "hooks/usePermission";

export default function ListCardType({
  dataTypeCard,
  error,
  pageIndexAndPageSize,
  entriesStart,
  entriesEnd,
  totalData,
  totalPages,
  currentPage,
  handlePageChange,
  handleOpenNew,
  handleOpenEdit,
  handleClickDelete,
}) {
  // Verificar permisos
  const canCreate = usePermission("CardType.create");
  const canEdit = usePermission("CardType.edit");
  const canDelete = usePermission("CardType.delete");
  const canListCardType = usePermission("CardType.all");
  // const canListFilterCardType = usePermission("CardType.byFilter");
  // const canListParticipant = usePermission("Listar", "Participantes");
  return (
    <>
      {canCreate && (
        <MDBox style={{ display: "flex", justifyContent: "flex-end", marginRight: "2vw" }}>
          <MDButton onClick={handleOpenNew} variant="gradient" color="info">
            Crear
          </MDButton>
        </MDBox>
      )}
      {canListCardType && (
        <>
          <DataTable
            table={{
              columns: [
                { Header: "nombre", accessor: "name", width: "12%" },
                { Header: "creado", accessor: "created", width: "12%" },
                { Header: "actualizado", accessor: "updated", width: "12%" },
                { Header: "Acciones", accessor: "actions", width: "12%" },
              ],
              rows: dataTypeCard
                ? dataTypeCard.map((type) => {
                    return {
                      name: type.name,
                      created: new Date(type.created_at).toISOString().slice(0, 10),
                      updated: new Date(type.updated_at).toISOString().slice(0, 10),
                      actions: (
                        <>
                          <a
                            data-identifier={type.id}
                            data-name={type.name}
                            data-expired={type.expiration_time}
                            data-created={type.created_at}
                            data-updated={type.updated_at}
                            data-id={"actions"}
                            onClick={handleOpenEdit}
                            style={{ cursor: "pointer" }}
                          >
                            {/*<img src={verTablaImg}></img>*/}
                            {
                              <FindInPageOutlinedIcon
                                titleAccess="Detalles"
                                style={{
                                  fontSize: "200px",
                                  width: "24px",
                                  height: "24px",
                                  backgroundImage: "#000000",
                                }}
                              />
                            }
                          </a>
                          {canEdit && (
                            <>
                              <a
                                data-identifier={type.id}
                                data-name={type.name}
                                data-expired={type.expiration_time}
                                data-created={type.created_at}
                                data-updated={type.updated_at}
                                data-id={"edit"}
                                onClick={handleOpenEdit}
                                style={{ marginLeft: "8px", cursor: "pointer" }}
                              >
                                <EditOutlinedIcon
                                  titleAccess="Editar"
                                  style={{
                                    fontSize: "200px",
                                    width: "24px",
                                    height: "24px",
                                    backgroundImage: "#000000",
                                  }}
                                ></EditOutlinedIcon>
                              </a>
                            </>
                          )}
                          {canDelete && (
                            <>
                              <a
                                data-identifier={type.id}
                                data-name={type.name}
                                data-expired={type.expiration_time}
                                data-created={type.created_at}
                                data-updated={type.updated_at}
                                data-id={"delete"}
                                onClick={handleClickDelete}
                                style={{ marginLeft: "8px", cursor: "pointer" }}
                              >
                                <DeleteForeverOutlinedIcon
                                  titleAccess="Eliminar"
                                  style={{
                                    fontSize: "200px",
                                    width: "24px",
                                    height: "24px",
                                    backgroundImage: "#000000",
                                  }}
                                ></DeleteForeverOutlinedIcon>
                              </a>
                            </>
                          )}
                        </>
                      ),
                    };
                  })
                : [],
            }}
            pageIndexAndPageSize={pageIndexAndPageSize}
            canSearch={false}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            checkboxSelection={false}
          />

          {error ? (
            <MDBox style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <MDTypography
                style={{ left: "20px", fontSize: "20px" }}
                variant="button"
                color="secondary"
                fontWeight="regular"
              >
                {error}
              </MDTypography>
            </MDBox>
          ) : dataTypeCard?.length === 0 || !dataTypeCard ? (
            <MDBox style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <MDTypography
                style={{ left: "20px", fontSize: "20px" }}
                variant="button"
                color="secondary"
                fontWeight="regular"
              >
                No hay datos
              </MDTypography>
            </MDBox>
          ) : (
            <>
              <MDBox style={{ marginLeft: "20px" }} mb={{ xs: 3, sm: 0 }}>
                <MDTypography
                  style={{ left: "20px" }}
                  variant="button"
                  color="secondary"
                  fontWeight="regular"
                >
                  Mostrando resultados {entriesStart} - {entriesEnd} de un total de {totalData}
                </MDTypography>
              </MDBox>
              <Stack style={{ marginLeft: "20px", marginBottom: "10px" }} spacing={2}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                ></Pagination>
              </Stack>{" "}
            </>
          )}
        </>
      )}
    </>
  );
}
