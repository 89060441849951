/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Rafael De Jesus Molina Haeckermann                         ║
║ rafael.molina@est.iudigital.edu.co                         ║
╚════════════════════════════════════════════════════════════╝
*/
import * as React from "react";
import {
  Autocomplete,
  Box,
  Card,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Icon,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import MDInput from "components/MDInput/index";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import colors from "assets/theme/base/colors";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";

export default function ViewUserPerCard({
  isOpenModal,
  dataCarnet,
  handleCloseToModal,
  isDisabled,
  getFieldValue,
  typeCarnets,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "700px",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    maxHeight: "90vh",
  };

  const content = {
    margin: "0",
    padding: "0",
    display: "grid",
    align: "center",
    width: "100%",
    gridTemplateColumns: {
      xs: "repeat(auto-fit, minmax(200px, 1fr))",
      md: "repeat(2, 1fr)",
    },
    gap: "15px",
  };

  return (
    <Modal
      open={isOpenModal}
      onClose={handleCloseToModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <MDBox
          mt={-7}
          color="white"
          bgColor="info"
          variant="gradient"
          borderRadius="lg"
          p={2}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Typography id="modal-modal-title" component="h2">
            Detalles del usuario:
            <br />
            {` ${getFieldValue("name_user")} ${getFieldValue("last_name_user")}`}
          </Typography>

          <Icon
            sx={{
              fontSize: "2em",
              stroke: "currentColor",
              strokeWidth: "2px",
              cursor: "pointer",
              marginLeft: "auto",
            }}
            onClick={handleCloseToModal}
          >
            close
          </Icon>
        </MDBox>
        <Divider variant="middle" />
        <div style={{ maxHeight: "80%", overflowY: "auto" }}>
          <MDBox
            sx={content}
            style={{
              justifyContent: "space-around",
            }}
          >
            <Box>
              <Typography id="modal-modal-description" color="black">
                Documento de identidad
              </Typography>
              <MDInput
                sx={{ width: "100%" }}
                disabled={isDisabled}
                style={{ marginTop: "5px" }}
                label=""
                value={getFieldValue("number_id_user")}
              />
            </Box>
            <Box>
              <Typography id="modal-modal-description" color="black">
                Perfil
              </Typography>
              <MDInput
                sx={{ width: "100%" }}
                disabled={isDisabled}
                style={{ marginTop: "5px" }}
                label=""
                value={getFieldValue("type_card")}
              />
            </Box>
            <Box>
              <Typography id="modal-modal-description" color="black">
                Correo electrónico
              </Typography>
              <MDInput
                sx={{ width: "100%" }}
                disabled={isDisabled}
                style={{ marginTop: "5px" }}
                label=""
                value={getFieldValue("email_user")}
              />
            </Box>
            <Box>
              <Typography id="modal-modal-description" color="black">
                Tipo de sangre
              </Typography>
              <MDInput
                sx={{ width: "100%" }}
                disabled={isDisabled}
                style={{ marginTop: "5px" }}
                label=""
                value={getFieldValue("blood_type")}
              />
            </Box>
          </MDBox>
          <Divider variant="middle" />
          <MDBox
            sx={content}
            style={{
              justifyContent: "space-around",
            }}
          >
            {getFieldValue("data_source") && (
              <Box>
                <Typography id="modal-modal-description" color="black">
                  Fuente de datos
                </Typography>
                <MDInput
                  sx={{ width: "100%" }}
                  disabled={isDisabled}
                  style={{ marginTop: "5px" }}
                  label=""
                  value={getFieldValue("data_source")}
                />
              </Box>
            )}
            <Box>
              <Typography id="modal-modal-description" color="black">
                Estado
              </Typography>
              <MDInput
                sx={{ width: "100%" }}
                disabled={isDisabled}
                style={{ marginTop: "5px" }}
                label=""
                value={getFieldValue("status")}
              />
            </Box>
          </MDBox>
          <Divider variant="middle" />
          <MDBox
            sx={content}
            style={{
              justifyContent: "space-around",
            }}
          >
            <Box>
              <Box>
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2 }}
                  color="black"
                  fontWeight={500}
                >
                  Fecha de vigencia
                </Typography>
              </Box>
            </Box>
          </MDBox>
          <MDBox
            sx={content}
            style={{
              justifyContent: "space-around",
            }}
          >
            <Box>
              <Typography id="modal-modal-description" color="black">
                Inicio
              </Typography>
              <MDInput
                sx={{ width: "100%" }}
                type="date"
                style={{ marginTop: "5px" }}
                label=""
                disabled={isDisabled}
                value={getFieldValue("created")}
              />
            </Box>
            <Box>
              <Typography id="modal-modal-description" color="black">
                Fin
              </Typography>
              <MDInput
                sx={{ width: "100%" }}
                type="date"
                style={{ marginTop: "5px" }}
                label=""
                disabled={isDisabled}
                value={getFieldValue("expired")}
              />
            </Box>
          </MDBox>
        </div>
        <Divider variant="middle" />
        <MDBox
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
          m={-0.5}
          mb={-2}
        >
          <MDButton color="error" onClick={handleCloseToModal}>
            Cancelar
          </MDButton>
          <MDButton type="submit" disabled color="success">
            Guardar
          </MDButton>
        </MDBox>
      </Card>
    </Modal>
  );
}
