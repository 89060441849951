/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Victor Manuel Calle Londoño                                ║
║ victor.calle@est.iudigital.edu.co                          ║
╚════════════════════════════════════════════════════════════╝
*/
import DataTable from "examples/Tables/DataTable";
import viewSource from "assets/images/ver_fuente.svg";
import editSource from "assets/images/edit_fuente.svg";
import deleteSource from "assets/images/delete_fuente.svg";
import closeImg from "assets/images/close_X.svg";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import MDInput from "components/MDInput";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { createSource, getSources, updateSource } from "../services/dataSourceService";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  CircularProgress,
  Pagination,
  Stack,
} from "@mui/material";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FindInPageOutlinedIcon from "@mui/icons-material/FindInPageOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { useNavigate } from "react-router-dom";
import usePermission from "hooks/usePermission";

export default function ListDataSource({
  handleOpenNew,
  sourceList,
  handleOpenEdit,
  handleClickDelete,
  isAlertSuccess,
  handleCloseAlertSuccess,
  infoSuccess,
  handleOpenDataSourcesBoard,
  loading2,
  entriesStart,
  entriesEnd,
  totalData,
  totalPages,
  currentPage,
  handlePageChange,
}) {
  const navigate = useNavigate();
  const handleNavigation = (id, name) => {
    sessionStorage.setItem("nameSource", name);
    navigate(`/Fuentes/${id}`, { state: { name } });
  };

  const canCreateFile = usePermission("Upload.file.create");
  const canCreateDB = usePermission("Upload.db.create");
  const canListFilePerSource = usePermission("DataSourceFile.byDataSourceId");
  const canListSourceData = usePermission("DataSource.all");
  return (
    <>
      {(canCreateFile || canCreateDB) && (
        <MDBox style={{ display: "flex", justifyContent: "flex-end", marginRight: "2vw" }}>
          <MDButton onClick={handleOpenNew} variant="gradient" color="info">
            Crear
          </MDButton>
        </MDBox>
      )}
      {canListSourceData && (
        <>
          <DataTable
            table={{
              columns: [
                { Header: "nombre", accessor: "source_name" },
                { Header: "Tipo de fuente", accessor: "source_type_name" },
                { Header: "Responsable", accessor: "username" },
                { Header: "cantidad de carnets", accessor: "carnets_quantity" },
                { Header: "Creado", accessor: "created" },
                { Header: "Acciones", accessor: "actions" },
              ],
              rows: sourceList
                ? sourceList.map((f) => {
                    return {
                      id: f.id,
                      source_name: `${f.source_name}`,
                      source_type_name: f.source_type_name,
                      username: f.user_email,
                      carnets_quantity: f.carnets_quantity,
                      created: new Date(f.created_at).toISOString().slice(0, 10),
                      actions: (
                        <>
                          {canListFilePerSource && (
                            <a
                              data-identifier={f.id}
                              data-name={f.source_name}
                              title={"Listado de archivos"}
                              // onClick={handleOpenDataSourcesBoard}
                              style={{
                                cursor: "pointer",
                                textDecoration: "none",
                                color: "inherit",
                              }}
                              onClick={() => handleNavigation(f.id, f.source_name)}
                              // href={`/Fuentes/${f.id}`}
                            >
                              <PreviewOutlinedIcon
                                style={{
                                  fontSize: "200px",
                                  width: "24px",
                                  height: "24px",
                                  backgroundImage: "#000000",
                                }}
                              />
                            </a>
                          )}
                        </>
                      ),
                    };
                  })
                : [],
            }}
            canSearch={false}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
          ></DataTable>
          {loading2 && (
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          )}
          {!sourceList ? (
            <MDBox style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <MDTypography
                style={{ left: "20px", fontSize: "20px" }}
                variant="button"
                color="secondary"
                fontWeight="regular"
              >
                No hay datos
              </MDTypography>
            </MDBox>
          ) : (
            <>
              <MDBox style={{ marginLeft: "20px" }} mb={{ xs: 3, sm: 0 }}>
                <MDTypography
                  style={{ left: "20px" }}
                  variant="button"
                  color="secondary"
                  fontWeight="regular"
                >
                  Mostrando resultados {entriesStart} - {entriesEnd} de un total de {totalData}
                </MDTypography>
              </MDBox>
              <Stack style={{ marginLeft: "20px", marginBottom: "10px" }} spacing={2}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                ></Pagination>
              </Stack>{" "}
            </>
          )}
        </>
      )}
    </>
  );
}
