import { useState, useEffect } from "react";
import {
  Box,
  Divider,
  Typography,
  Grid,
  Card,
  CardContent,
  FormControlLabel,
  Checkbox,
  Paper,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

export function EditRole({
  allPermissions = [],
  permissionsByRolList = [],
  handleUpdatePermissions,
  handleFieldChange,
  roleData,
}) {
  const groupPermissionsBySection = (allPermissions) => {
    return Object.keys(allPermissions).map((section) => {
      return {
        section,
        permissions: allPermissions[section],
      };
    });
  };

  const groupedPermissions = groupPermissionsBySection(allPermissions);
  const [selectedPermissions, setSelectedPermissions] = useState(permissionsByRolList);

  const handleCheckboxChange = (permissionId) => {
    setSelectedPermissions((prev) => {
      const isChecked = prev.some((permission) => permission.id === permissionId);
      let updatedPermissions;

      if (isChecked) {
        updatedPermissions = prev.filter((permission) => permission.id !== permissionId);
      } else {
        const permissionToAdd = Object.values(allPermissions)
          .flat()
          .find((permission) => permission.id === permissionId);
        updatedPermissions = [...prev, permissionToAdd];
      }

      return updatedPermissions;
    });
  };

  return (
    <>
      <MDBox style={{ marginRight: "2vw", marginTop: "10px", marginBottom: "5px" }}>
        <Divider />
        <Box>
          <MDBox style={{ display: "flex", justifyContent: "space-around" }}>
            <MDBox style={{ width: "97%" }}>
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Nombre:
              </Typography>
              <MDInput
                style={{ width: "100%", marginTop: "5px" }}
                label="Nombre"
                value={roleData?.name}
                onChange={(e) => handleFieldChange("name", e.target.value)}
              />
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
                style={{
                  marginTop: "15px",
                }}
              >
                Descripción:
              </Typography>
              <MDInput
                style={{ width: "100%", marginTop: "5px" }}
                label="Descripcion"
                multiline
                rows={3}
                value={roleData?.description}
                onChange={(e) => handleFieldChange("description", e.target.value)}
              />
            </MDBox>
          </MDBox>
        </Box>
        <Divider variant="middle" />

        {/* Cards para las secciones y permisos */}
        <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center", margin: "5px" }}>
          {groupedPermissions.map((group) => (
            <Card key={group.section} sx={{ width: "365px", margin: "5px" }}>
              <CardContent sx={{ padding: 2 }}>
                <Typography variant="h6" style={{ textAlign: "center" }}>
                  {group.section}
                </Typography>
                {group.permissions.map((permission) => (
                  <FormControlLabel
                    sx={{
                      marginRight: 0,
                      display: "flex",
                      "@media (max-width: 450px)": {
                        padding: "5px",
                      },
                    }}
                    key={permission.id}
                    control={
                      <Checkbox
                        checked={selectedPermissions.some(
                          (selectedPermission) => selectedPermission.id === permission.id
                        )}
                        onChange={() => handleCheckboxChange(permission.id)}
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 20 },
                        }}
                      />
                    }
                    label={permission.action}
                  />
                ))}
              </CardContent>
            </Card>
          ))}
        </Box>

        {/* Botón de actualizar */}
        <MDBox style={{ display: "flex", justifyContent: "center", margin: "20px" }}>
          <MDButton onClick={() => handleUpdatePermissions(selectedPermissions)} color="success">
            Actualizar
          </MDButton>
        </MDBox>
      </MDBox>
    </>
  );
}
