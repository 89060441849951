import React, { useState } from "react";
import { Divider, Typography } from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import DataTable from "examples/Tables/DataTable";
import FindInPageOutlinedIcon from "@mui/icons-material/FindInPageOutlined";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MDBadge from "components/MDBadge";

export default function VerifyByDocument({
  handleFieldChange,
  handleSubmit,
  data,
  pageIndexAndPageSize,
  handleSearch,
  isLoading,
}) {
  const [hasSearched, setHasSearched] = useState(false);

  const handleSearchSubmit = () => {
    setHasSearched(true);
    handleSubmit();
  };

  return (
    <>
      <Typography variant="h3" component="h1" gutterBottom>
        ¡Bienvenido a la página de verificación de carnets de la IU Digital!
      </Typography>
      <Typography variant="subtitle1" color="textSecondary" paragraph>
        Digita el número de documento:
      </Typography>
      <MDInput
        style={{ marginTop: "5px" }}
        label=""
        onChange={(e) => handleFieldChange("name", e.target.value)}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault(); // Previene comportamiento por defecto
            handleSearchSubmit(); // Llamar la función sin pasar
          }
        }}
      />
      <Divider variant="middle" />
      <MDButton onClick={handleSearchSubmit} type="submit" color="success">
        Consultar
      </MDButton>
      <div
        style={{
          width: "auto",
          margin: "2rem",
        }}
      >
        {isLoading ? (
          <div>Cargando...</div>
        ) : data && data.length > 0 ? (
          <DataTable
            table={{
              columns: [
                { Header: "Estado", accessor: "status", width: "12%" },
                { Header: "Fecha de Creación", accessor: "created_at", width: "12%" },
                { Header: "Fecha de Expiración", accessor: "expires_in", width: "12%" },
                { Header: "Tipo de Carnet", accessor: "Tipo_carnet", width: "12%" },
                { Header: "Ingreso", accessor: "joined", width: "12%" },
                { Header: "Acciones", accessor: "view", width: "12%" },
              ],
              rows: data?.map((item) => ({
                status:
                  item.status === "Activo" ? (
                    <MDBadge badgeContent="Activo" size="xs" container color="success" />
                  ) : (
                    <MDBadge badgeContent={item.status} size="xs" container color="error" />
                  ),
                created_at: new Date(item.created).toISOString().slice(0, 10),
                expires_in: new Date(item.expires_in).toISOString().slice(0, 10),
                Tipo_carnet: item.Tipo_carnet,
                joined:
                  item.Tipo_carnet === "Invitado" || item.Tipo_carnet === "Visitante" ? (
                    item.joined == 1 ? (
                      <CheckCircleIcon style={{ color: "green" }} />
                    ) : (
                      <QueryBuilderIcon style={{ color: "red" }} />
                    )
                  ) : (
                    "N/A"
                  ),
                view: (
                  <>
                    <a style={{ cursor: "pointer" }} href={`/verify/${item.id}/false`}>
                      <FindInPageOutlinedIcon
                        style={{
                          fontSize: "200px",
                          width: "24px",
                          height: "24px",
                          backgroundImage: "#000000",
                        }}
                      ></FindInPageOutlinedIcon>
                    </a>
                  </>
                ),
              })),
            }}
            pageIndexAndPageSize={pageIndexAndPageSize}
            canSearch={false}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            checkboxSelection={false}
          />
        ) : (
          // Mostrar el mensaje cuando no hay resultados
          <div>
            {hasSearched ? "No hay resultados" : "Realiza una búsqueda para ver los resultados"}
          </div>
        )}
      </div>
    </>
  );
}
